@import "./variables";

.login-form,
.register-form,
.verify-otp-form,
.forgot-password-form,
.reset-password-form {
  // @apply mx-auto w-full max-w-[400px] #{!important};

  .forgot-btn {
    display: block;
    margin: 1rem 0;
  }

  .submit-btn {
    width: 100%;
  }

  .footer {
    text-align: center;

    .ant-typography {
      display: block;
    }
  }

  .ant-form-item-label {
    display: none;
  }

  .alert {
    margin-bottom: 1.5rem;
  }
}

.verify-otp-form {
  .ant-form-item-explain-error {
    text-align: center;
  }
  .action-btn {
    display: flex;
    gap: 0.5rem;
    > * {
      width: 100%;
    }
  }
}

.product-item {
  .ant-card-cover {
    img {
      height: auto;
    }
  }
  .ant-card-body {
    text-align: center;
  }

  .product-name {
    margin-bottom: 0.5rem;
  }

  .product-price {
    margin-bottom: 0.5rem;
  }
}

.product-item-loader {
  text-align: center;
  .ant-skeleton-image {
    width: 100% !important;
    height: 230px !important;
  }

  .product-name {
    .ant-skeleton-input {
      margin-bottom: 0.5rem;
      height: 1.5em;
    }
  }

  .product-price {
    .ant-skeleton-input {
      margin-bottom: 0.5rem;
      width: 50%;
      height: 1em;
    }
  }
  .ant-skeleton-button {
    width: 60% !important;
  }
}

.product-image-slider {
  aspect-ratio: 1/1;
  .product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.swiper-pagination-bullet {
  opacity: 1 !important;
  background-color: #e2e2e2 !important;
  width: 13px !important;
  height: 13px !important;
}
.swiper-pagination-bullet-active {
  background-color: $primary-color !important;
}

.swatch-color,
.swatch-image {
  .selected-value {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 1.1em;
  }
  .ant-radio-group {
    display: flex;
    gap: 0.25rem;

    label {
      padding: 0;
      span {
        display: block;
        width: 100%;
        height: 100%;

        &.color,
        &.image {
          border: 1px solid #ddd;
          border-radius: 100%;
          background: transparent;
          width: 32px;
          height: 100%;
        }
      }
    }
    .ant-radio-button-wrapper,
    .ant-radio-button {
      border: none;
      border-radius: 100%;
      background: transparent;

      &::before {
        display: none;
      }
    }
  }
}

.swatch-button {
  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    .ant-radio-button-wrapper {
      display: block;
      border: 1px solid #ddd;
      border-radius: 7px;
      padding: 1rem 1rem;
      width: 100%;
      height: auto;
      &::before {
        display: none;
      }

      span {
        font-size: 1.15em;
      }
    }
  }
}

.swatch-color,
.swatch-image,
.swatch-button {
  .ant-form-item-label {
    display: none;
  }
  .label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 1.25em;
    .description {
      color: #a5a5a5;
    }
  }
  .ant-radio-group {
    .ant-radio-button-wrapper-checked {
      border-width: 2px;
      border-color: #00a9a5;
      color: #000;
      .color,
      .image {
        border-width: 2px;
        border-color: #00a9a5;
      }
    }
  }
}

.swatch-button.pickup-time-slot,
.swatch-button.bank-installment {
  .ant-form-item-label {
    display: block;
  }
}

.swiper-slide {
  height: auto;
}

.ant-btn.ant-btn-primary {
  background-color: $primary-color;
}

.ant-picker-calendar {
  .ant-picker-cell-disabled::before {
    background: transparent;
  }
  .ant-picker-cell-inner.ant-picker-calendar-date {
    border-radius: 100%;
    min-width: 28px;
    height: 28px;
    line-height: 28px;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today {
    .ant-picker-cell-inner::before {
      border: 1px solid rgb(129 188 191 / 22%);
      border-radius: 100%;
    }
  }
}

/** Countdown Timer */
// .ant-statistic-content-value {
//   @apply text-4xl;
// }

.countdown-timer {
  .ant-statistic-title {
    @apply text-2xl font-medium text-black #{!important};
  }

  .ant-statistic-content-value {
    @apply text-4xl font-semibold #{!important};
  }
}

.bank-promotion .ant-tabs-tab-btn {
  @apply text-lg font-semibold md:text-2xl;
}

// :where(
//     .css-dev-only-do-not-override-auikln
//   ).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
//   background-color: $primary-color;
//   color: #fff;
// }

// .ant-radio-group {
//   .ant-radio-button-wrapper {
//     &.ant-radio-button-wrapper-checked {
//       &:hover {
//         color: #fff;
//       }
//     }
//     &:hover {
//       color: inherit;
//     }
//   }
// }
